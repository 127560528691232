<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        sm="3"
      >
        <b-form-group>
          <h6 class="text-primary">
            Diurno / Nocturno
          </h6>
          <b-form-input
            :value="brecha.tipoFalla.nombre"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        sm="4"
      >
        <b-form-group>
          <h6 class="text-primary">
            Poste
          </h6>
          <b-form-input
            :value="brecha.poste"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        sm="5"
      >
        <b-form-group>
          <h6 class="text-primary">
            Brigada
          </h6>
          <b-form-input
            :value="brecha.brigada.nombre"
            disabled
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <h3 class="text-primary">
          Trabajos Realizados
          <hr>
        </h3>
      </b-col>
      <b-col
        cols="12"
        sm="12"
      >
        <b-form-group>
          <h6 class="text-primary">
            Trabajos Realizados
          </h6>
          <b-form-input
            :value="getTrabajosRealizados()"
            disabled
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="brecha.trabajosRealizados.find(item => item.id === 1)">
      <b-col
        cols="12"
        sm="12"
      >
        <b-form-group>
          <h6 class="text-primary">
            Metros de Becha
          </h6>
          <b-form-input
            :value="brecha.trabajosRealizados.find(item => item.id === 1) && brecha.trabajosRealizados.find(item => item.id === 1).trabajo"
            disabled
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="brecha.trabajosRealizados.find(item => item.id === 2)">
      <b-col
        cols="12"
        sm="6"
      >
        <b-form-group>
          <h6 class="text-primary">
            Brazo Retirado
          </h6>
          <b-form-input
            :value="brecha.trabajosRealizados.find(item => item.id === 2) && brecha.trabajosRealizados.find(item => item.id === 2).trabajo.brazoRetirado.nombre"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        sm="6"
      >
        <b-form-group>
          <h6 class="text-primary">
            Brazo Nuevo
          </h6>
          <b-form-input
            :value="brecha.trabajosRealizados.find(item => item.id === 2) && brecha.trabajosRealizados.find(item => item.id === 2).trabajo.brazoInstalado.nombre"
            disabled
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="brecha.trabajosRealizados.find(item => item.id === 3)">
      <b-col
        cols="12"
        sm="6"
      >
        <b-form-group>
          <h6 class="text-primary">
            Código de Lámpara Retirada
          </h6>
          <b-form-input
            :value="brecha.trabajosRealizados.find(item => item.id === 3) && brecha.trabajosRealizados.find(item => item.id === 3).trabajo.codigoAnterior"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        sm="6"
      >
        <b-form-group>
          <h6 class="text-primary">
            Potencia de Lámpara Retirada
          </h6>
          <b-form-input
            :value="brecha.trabajosRealizados.find(item => item.id === 3) && brecha.trabajosRealizados.find(item => item.id === 3).trabajo.potenciaAnterior"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        sm="6"
      >
        <b-form-group>
          <h6 class="text-primary">
            Código de Lámpara Instalada
          </h6>
          <b-form-input
            :value="brecha.trabajosRealizados.find(item => item.id === 3) && brecha.trabajosRealizados.find(item => item.id === 3).trabajo.codigoNuevo"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        sm="6"
      >
        <b-form-group>
          <h6 class="text-primary">
            Potencia de Lámpara Instalada
          </h6>
          <b-form-input
            :value="brecha.trabajosRealizados.find(item => item.id === 3) && brecha.trabajosRealizados.find(item => item.id === 3).trabajo.potenciaNueva"
            disabled
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <vue-good-table
          :columns="columns"
          :rows="brecha.seguimiento"
          style-class="vgt-table condensed"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field == 'detalle'">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.modal-1
                variant="gradient-primary"
                @click="mostrarImagen(props.row)"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
              <p v-if="props.row.comentarios">{{ props.row.comentarios }}</p>
            </span>
            <span v-else-if="props.column.field == 'fechaHora'">
              <span>{{ formatFecha(brecha.fechaCreacion) }}</span>
            </span>
          </template>
        </vue-good-table>
      </b-col>
    </b-row>
    <div>
      <b-modal
        v-model="verDialogoDocumento"
        ok-only
        ok-variant="primary"
        ok-title="Aceptar"
        modal-class="modal-primary"
        centered
        size="lg"
        title="Detalle de la Imagen"
      >
        <b-card-text style="display: flex; justify-content: center">
          <b-img
            :src="urlImagen"
            fluid
            alt="Fluid-grow image')"
            style="max-height: 700px;"
          />
        </b-card-text>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BFormInput,
  BRow, BCol, BFormGroup, BButton, BModal, VBModal, BCardText, BImg,
} from 'bootstrap-vue'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import { calcularFecha } from '@/utils/fechas'

export default {
  components: {
    VueGoodTable,
    BCardText,
    BFormInput,
    BFormGroup,
    BModal,
    BImg,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    brecha: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      urlImagen: '',
      verDialogoDocumento: false,
      columns: [
        {
          label: 'Tipo',
          field: 'tipo',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
        {
          label: 'Fecha/Hora',
          field: 'fechaHora',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
        {
          label: 'Detalle',
          field: 'detalle',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
      ],
      rows: [],
    }
  },
  methods: {
    getTrabajosRealizados() {
      return this.brecha.trabajosRealizados.map(trabajo => trabajo.nombre).join(', ')
    },
    mostrarImagen(file) {
      this.urlImagen = ''
      this.urlImagen = file.url
      this.verDialogoDocumento = true
    },
    formatFecha(row) {
      return calcularFecha(row)
    },
  },
}
</script>

<style scoped>

</style>
